@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
@import url(https://code.highcharts.com/css/highcharts.css);
html, body, #root {
    height: 100% !important;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
@media all and (min-width: 480px) {
    .view {
    }

    .message, .errorMessage {
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 20px;
    }

    .errorMessage {
        color: red;
    }

    .successMessage {
        color: green;
    }

    .legend, .globalLegend {
        margin: auto 0;
        color: #114084;
        /*font-size: 24px;*/
    }
    
    .legend {
        font-size: 20px;
    }

    .breadcrumbs {
        margin: auto 0;
        color: #114084;
        /*font-size: 24px;*/
    }

    .breadcrumbs {
        font-size: 20px;
    }

    .mainContainer {
        min-height: 100vh; /* will cover the 100% of viewport */
        width: 100%;
        overflow-y: hidden;
        display: block;
        position: relative;
       
    }
    
    .main-container {
        min-height: 100vh; /* will cover the 100% of viewport */
        width: 100%;
        overflow-y: hidden;
        display: block;
        position: relative;
       
    }

    .main-scroll {
        height: 100%;
    }

    #roo::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #roo::-webkit-scrollbar {
        width: 0px;
        background-color: #F5F5F5;
    }

    #roo::-webkit-scrollbar-thumb {
        background-color: #787878;
    }
}
@media all and (min-width: 480px) {
}
@media all and (min-width: 480px) {
    .MuiOutlinedInput-root, .MuiFormLabel-root {
        margin-top: 8px;
    }

    .MuiFormControl-root {
        margin: 0 !important;
    }

    .custom-scrollbar {
        position: relative;
        height: 200px;
        overflow: auto;
    }

    .formContainer {
        padding-top: 4px;
    }

    .form-col {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .search-form-container {
        width: 98%;
        overflow: hidden;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.btn:hover {
    opacity: .9;
}

.btn.disabled, .btn:disabled, .btn.disabled:hover {
    opacity: 0.3 !important;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
	outline: none !important;
}

.message, .errorMessage {
    font-size: 12px !important;
    font-weight: 400;
    padding-left: 16px;
}

.errorMessage {
    color: red;
}

.dash-logo{
height:96px;
width:96px;
}
.cust-menu{
width:20%;
}

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .img-responsive {
        width: 50%;
        margin-top: 50px;
    }

    .card-container.sec-card {
        margin: 0px 0px 0px;
    }

    .img-responsive {
        margin-top: 50px;
    }

    .dash-logo {
        height: 20px;
        width: 20px;
    }

    .flex-column {
        width: 6.5rem !important;
    }
    .col-sm-6{
      z-index: -1;
    }
    .view {
        z-index: -1;
    }
    .cust-menu {
        width: 10%;
    }
    .menu-table {
       display:none;
    }
        .legend, .globalLegend {
            text-align: center;
        }

        .breadcrumbs {
            text-align: center;
        }
    }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .img-responsive {
        margin-top: 50px;
    }
   
    .flex-column {
        width: 14rem !important;
    }
}
@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

    .tabPanel div:first-child{
        height: auto !important;
    }
}
.table thead th {
}

table.responsive-table {
    display: table;
    /* required for table-layout to be used (not normally necessary; included for completeness) */
    table-layout: fixed;
    /* this keeps your columns with fixed with exactly the right width */
    width: 100% !important;
    /* table must have width set for fixed layout to work as expected */
    height: 100%;
}

table.responsive-table thead {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        width: 100%;
        height: 50px;
        line-height: 3em;
        background: #eee;
        table-layout: fixed;
        display: table;
    }

    table.responsive-table th {
        background: #eee;
    }

    table.responsive-table td {
        line-height: 2em;
    }

    table.responsive-table tr > td,
    table.responsive-table th {
        text-align: left;
    }

.table td, .table th {
    /*white-space: nowrap;*/
    overflow: hidden !important;
    text-overflow: ellipsis;
    min-width: 0 !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: none !important;
}

.selectCell {
    white-space: normal !important;
    text-overflow: unset !important;
}

.selectCell span{
    padding: 0 !important;
}

.table td {
    padding: 6px 24px 6px 16px;
    border-top: none !important;
    display: table-cell;
    font-size: 0.700rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /*font-weight: 400;*/
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.table th {
    /*color: rgba(0, 0, 0, 0.54);*/
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.3125rem;
    word-wrap:break-word;
}

.table {
    table-layout: fixed;
}

    .table-scrollbar {
    position: relative;
    overflow: auto;
}

@media screen and (max-width: 40em) {
 
  
}

@media all and (min-width: 480px) {
    .btn-primary {
        border-color: transparent;
    }

    #menubar-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #menubar-scroll::-webkit-scrollbar {
        width: 6px !important;
        background-color: #F5F5F5;
    }

    #menubar-scroll::-webkit-scrollbar-thumb {
        background-color: #939393 !important;
    }

    .menu-table button {
        height: 96px;
        width: 100%;
        border-radius: 0 !important;
        text-decoration:none;
    }

    .menu-table .toolStripButtonSelected {
        text-decoration: none;
    }

    .menu-table .btn-primary:focus {
        box-shadow: none;
        text-decoration: none;
    }
    .footer {
        font-size: 14px;
        text-align: center;
        color: white;
        height: 10vh;
        padding-top: 8px;
    }

    .react-pdf__Page__canvas{
        width: 100% !important;
    }

    .header {
        background-clip:border-box;
        border-bottom: 1px solid #e1e1e1;
    }

    .header shadow {
        /**box-shadow: 0 0.15rem 1.75rem 0 rgba(40, 41, 55, 0.15) !important;**/
    }

        .header span {
            /**color: #fff;**/
    }

    .header .form-inline .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        background-color: #f8f9fc !important;
        border: 0 !important;
    }

    .btn-primary, .btn, .btn-primary:hover, .btn:hover, .btn-primary:focus, .btn:focus {
        outline: none !important;
        outline-offset: unset !important;
        border-color: transparent !important;
        box-shadow: none !important;
        text-decoration: none;
    }

    .header .btn-primary {
        display: block;
        color: #fff;
        border-radius: 0px;
        /*box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.2);*/
        /*background-color: #114084;*/
        border-top: 1px solid rgba(255,255,255,0.5) !important;
        margin-top: -5px;
        text-decoration: none;
    }

    .header .btn {
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
                user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 2;
        width: 100%;
        text-align:left;
        text-decoration:none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .border {
        border: 0px solid #dee2e6 !important;
    }

    .header .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: none !important;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }

    .search .card-body {
        height: 60vh !important;
        padding: 0.6rem;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }
}
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 2000; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	background-color: transparent;
	margin: 30% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 10%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addPersonSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .personFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

}


@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .qualificationSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: 50vh;
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: 50vh;
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .unitStandardFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}



@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .providerForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .providerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .skillsProgrammeSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnershipSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .providerForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
    .providerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .BursarySetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .BursarySetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 300px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .filterRow {
        overflow-y: hidden;
    }

    .filterContainer {
        width: 300px;
    }
}

.OnlineRegistration-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/foodbevlogin.6c6e3fe6.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

@media all and (min-width: 480px) {


    .OnlineRegistrationSelect {
        //padding: 60px 0;
        color: #fff;
    }

    .OnlineRegistration-btn {
        background: #28A745 !important;
        color: #fff;
    }

    .OnlineRegistrationSelect form,
    .OnlineRegistrationSelect table,
    .OnlineRegistrationSelect p {
        margin: 0 auto;
        max-width: 640px;
        max-height: 400px;
        overflow: auto;
    }

    .OnlineRegistrationSelect p {
        margin-bottom: 32px;
        font-size: 30px;
        color: #000;
    }

    .OnlineRegistrationSelect span {
        color: #000;
    }

    .OnlineRegistrationSelect table {
        margin-top: 20px;
    }

    .OnlineRegistrationSelect label {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }

    .OnlineRegistrationSelect input[type="checkbox"], input[type="radio"] {
        position: absolute;
        right: 9000px;
    }

        /*Check box*/
        .OnlineRegistrationSelect input[type="checkbox"] + .label-text:before {
            content: "\F0C8";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

        .OnlineRegistrationSelect input[type="checkbox"]:checked + .label-text:before {
            content: "\F14A";
            color: #2980b9;
            animation: effect 250ms ease-in;
            font-weight: 900;
        }

        .OnlineRegistrationSelect input[type="checkbox"]:disabled + .label-text {
            color: #aaa;
        }

            .OnlineRegistrationSelect input[type="checkbox"]:disabled + .label-text:before {
                content: "\F0C8";
                color: #ccc;
            }

    /*Radio box*/

    .OnlineRegistrationSelect input[type="radio"] + .label-text:before {
        content: "\F111";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 5px;
    }

    .OnlineRegistrationSelect input[type="radio"]:checked + .label-text:before {
        content: "\F192";
        color: #000;
        animation: effect 250ms ease-in;
    }

    .OnlineRegistrationSelect input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

    input[type="radio"]:disabled + .label-text:before {
        content: "\F111";
        color: #ccc;
    }

    /*Radio Toggle*/

    .OnlineRegistrationSelect .toggle input[type="radio"] + .label-text:before {
        content: "\F204";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: 900;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .OnlineRegistrationSelect .toggle input[type="radio"]:checked + .label-text:before {
        content: "\F205";
        color: #16a085;
        animation: effect 250ms ease-in;
    }

    .OnlineRegistrationSelect .toggle input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

        .OnlineRegistrationSelect .toggle input[type="radio"]:disabled + .label-text:before {
            content: "\F204";
            color: #ccc;
        }

    /*Scroller */

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
    }



    @keyframes effect {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.3);
        }

        75% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1);
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .OnlineRegistrationSelect {
        /* background-repeat: no-repeat;
        background-image: url('/images/OnlineRegistrations_background.jpeg');
        background-size: 100% 100%;*/
        color: #fff;
        padding: 60px;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .OnlineRegistrationSelect {
        padding: 60px 0;
        color: #fff;
    }

        .OnlineRegistrationSelect form,
        .OnlineRegistrationSelect table,
        .OnlineRegistrationSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 800px;
            overflow: auto;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: 50vh;
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

    .tabPanel div:first-child {
            height: auto !important;
    }

    .wideDrop {
        width: 300px;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: 50vh;
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }

    .wideDrop {
        width: 300px;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: 50vh;
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .artisansSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addAETSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .aetSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }
    .my-custom-scrollbar {
        position: relative;
        height: 200px;
        overflow: auto;
    }
}
@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .qualificationSetupFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .moduleFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }
}
@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }

    .wideDrop {
        width: 300px;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }

    .wideDrop {
        width: 300px;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .learnerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

}
/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
#piecontainer {
    max-width: 800px;
    min-width: 320px;
    margin: 0 auto;
}

.highcharts-pie-series .highcharts-point {
    stroke: #EDE;
    stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
    stroke: silver;
    stroke-dasharray: 2, 2;
    stroke-width: 2px;
}
@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    /*.formContainer {
        padding-top: 32px;
    }*/

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

.btn-margin{
    margin-left: 38px;
    margin-bottom: -15px;
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
    .title{
        margin-top: 10px;
        text-align: center;
    }
  
}


.sdf-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/foodbevlogin.6c6e3fe6.jpg);
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
    height: 100vh;
    width: 100%;
    overflow-y: hidden !important;
}


.btn.btn-register button{
    
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    border-radius: 3px;
    border: none;
    transition: all 0.218s;
}

.submitButton {
    background-image:linear-gradient(to right, #00546f 0%, #60f4ff 100%);
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .organisationForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .organisationFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    
    .tabContainer {
        margin-top: 32px;
    }

    .stakeholderForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .stakeholderFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

.legend{
    font-size: 12,
}
.breadcrumbs {
    font-size: 12,
}

.formContainer{
    width: 100%;
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .organisationForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .organisationFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

/*input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/

input[type=number]{
  font-size:12px;
  padding: 5px;
  height: 30px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.space{
  height: 20px;
}

.FormsTableHeader {
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
    padding: 2px;
    margin: 0px;
    width: 100%;
}
@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

    .tabPanel div:first-child{
        height: auto !important;
    }
}
@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

/*input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/

input[type=number]{
  font-size:12px;
  padding: 5px;
  height: 30px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.space{
  height: 20px;
}

.FormsTableHeader {
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
    padding: 2px;
    margin: 0px;
    width: 100%;
}
/*input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/

input[type=number]{
  font-size:12px;
  padding: 5px;
  height: 30px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.space{
  height: 20px;
}

.FormsTableHeader {
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
    padding: 2px;
    margin: 0px;
    width: 100%;
}
@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }
}

/*input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/

input[type=number]{
  font-size:12px;
  padding: 5px;
  height: 30px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.space{
  height: 20px;
}

.FormsTableHeader {
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
    padding: 2px;
    margin: 0px;
    width: 100%;
}
@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }
}

#atmospheric-composition {
    width: 100%;
    height: 100%;
    color: white;
    margin: 0 auto;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


#barcontainer {
    height: 100%;
    width: 100%;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
}

/*
 * Specific styles of signin component
 */
/*
 * General styles
 */


#linecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}

.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
    color: #c0c0c0;
}


#piecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: #c0c0c0;
}


#piecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: #c0c0c0;
}

/*
 * Specific styles of signin component
 */
/*
 * General styles
 */


#linecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}

.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
    color: #c0c0c0;
}

#atmospheric-composition {
    width: 100%;
    height: 100%;
    color: white;
    margin: 0 auto;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


#barcontainer {
    height: 100%;
    width: 100%;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
}

#atmospheric-composition {
    width: 100%;
    height: 100%;
    color: white;
    margin: 0 auto;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


#barcontainer {
    height: 100%;
    width: 100%;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
    
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
}

/*
 * Specific styles of signin component
 */
/*
 * General styles
 */


#linecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}

.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
    color: #c0c0c0;
}


#piecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}


.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: #c0c0c0;
}

@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

/*
 * Specific styles of signin component
 */
/*
 * General styles
 */

.login-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/foodbevlogin.6c6e3fe6.jpg);
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
    height: 100vh;
    width: 100%;
    overflow-y: hidden !important;
}

.login-form{
margin-top:50px;
}
 .log-msg-none{
 display:none
 }
 .log-msg{
 display:block;
 }
.card-container.sec-card {
    /*max-width: 360px;
    padding: 5px 40px 40px 40px;  
    margin-top: 50px*/
    width: 360px;
    height: 360px;
    top: 35%;
    left: 50%;
    margin-top: -200px;
    margin-left: -180px;
    position: absolute;
}

/*
 * Card component
 */
.sec-card{
    //background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 0 25px 30px;
    margin: 0 auto 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    /* shadows and rounded borders */
    border-radius: 2px;
    /*-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.form-signin .inputEmail,
.form-signin .inputPassword {
    direction: ltr;
    height: 52px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    box-sizing: border-box;
}

    .form-signin .form-control:focus {
        border-color: rgb(104, 145, 162);
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    }

.btn.btn-signin button{
    
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    border-radius: 3px;
    border: none;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    
}

.loginButton {
    background-image: linear-gradient(to right, #1B2574 0%, #1B2574 100%);
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
    margin-bottom: 10px;
}

.ForgotPasswordButton {
}

.loginCopyright {
    top: 94%;
    left: 49%;
    color: black;
    margin-left: -205px;
    position: absolute;
}

.forgot-password {
    color: rgb(104, 145, 162);
}

    .forgot-password:hover,
    .forgot-password:active,
    .forgot-password:focus {
        color: rgb(12, 97, 33);
    }
.img-responsive {
    height: 180px;
    width: 510px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.img-foodbev-responsive {
    height: 220px;
    width: 210px;
    margin-top: 0px;
    margin-bottom: 30px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    body {
      padding:0px 0px 0px;       
    }

    .img-responsive {
        //width: 50%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    /*.card-container.sec-card {
        margin: 0px 0px 0px;
    }*/
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .img-responsive {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}


.role-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/foodbevlogin.6c6e3fe6.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

@media all and (min-width: 480px) {

   
    .RoleSelect {
        //padding: 60px 0;
        color:#fff;  
       
    }

    .role-btn{
        background: #28A745 !important;
        color:#fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 400px;
            overflow: auto;
        }

    .RoleSelect p {
        margin-bottom: 32px;
        font-size: 30px;
        color: #000;
    }

    .RoleSelect span {
        color: #000;
    }

        .RoleSelect table {
            margin-top: 20px;
        }

    .RoleSelect label {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }

    .RoleSelect input[type="checkbox"], input[type="radio"] {
        position: absolute;
        right: 9000px;
    }

        /*Check box*/
        .RoleSelect input[type="checkbox"] + .label-text:before {
            content: "\F0C8";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="checkbox"]:checked + .label-text:before {
        content: "\F14A";
        color: #2980b9;
        animation: effect 250ms ease-in;
        font-weight: 900;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text:before {
        content: "\F0C8";
        color: #ccc;
    }

        /*Radio box*/

     .RoleSelect  input[type="radio"] + .label-text:before {
            content: "\F111";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="radio"]:checked + .label-text:before {
        content: "\F192";
        color: #000;
        animation: effect 250ms ease-in;
    }

        .RoleSelect input[type="radio"]:disabled + .label-text {
            color: #aaa;
        }

            input[type="radio"]:disabled + .label-text:before {
                content: "\F111";
                color: #ccc;
            }

    /*Radio Toggle*/

    .RoleSelect .toggle input[type="radio"] + .label-text:before {
        content: "\F204";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: 900;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .RoleSelect .toggle input[type="radio"]:checked + .label-text:before {
        content: "\F205";
        color: #16a085;
        animation: effect 250ms ease-in;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text:before {
            content: "\F204";
            color: #ccc;
    }

    /*Scroller */

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
    }



    @keyframes effect {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.3);
        }

        75% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1);
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .RoleSelect {
       /* background-repeat: no-repeat;
        background-image: url('/images/roles_background.jpeg');
        background-size: 100% 100%;*/
        color:#fff;
        padding:60px; 
    } 

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .RoleSelect {
        padding: 60px 0;
        color: #fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 800px;
            overflow: auto;
        }
}

.role-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/sims_loginscreen.708027a0.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

@media all and (min-width: 480px) {

   
    .RoleSelect {
        //padding: 60px 0;
        color:#fff;  
       
    }

    .role-btn{
        background: #28A745 !important;
        color:#fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 400px;
            overflow: auto;
        }

        .RoleSelect p {
            margin-bottom: 32px;
            font-size: 30px;
        }

        .RoleSelect table {
            margin-top: 20px;
        }

    .RoleSelect label {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }

    .RoleSelect input[type="checkbox"], input[type="radio"] {
        position: absolute;
        right: 9000px;
    }

        /*Check box*/
        .RoleSelect input[type="checkbox"] + .label-text:before {
            content: "\F0C8";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="checkbox"]:checked + .label-text:before {
        content: "\F14A";
        color: #2980b9;
        animation: effect 250ms ease-in;
        font-weight: 900;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text:before {
        content: "\F0C8";
        color: #ccc;
    }

        /*Radio box*/

     .RoleSelect  input[type="radio"] + .label-text:before {
            content: "\F111";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="radio"]:checked + .label-text:before {
        content: "\F192";
        color: #000;
        animation: effect 250ms ease-in;
    }

        .RoleSelect input[type="radio"]:disabled + .label-text {
            color: #aaa;
        }

            input[type="radio"]:disabled + .label-text:before {
                content: "\F111";
                color: #ccc;
            }

    /*Radio Toggle*/

    .RoleSelect .toggle input[type="radio"] + .label-text:before {
        content: "\F204";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: 900;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .RoleSelect .toggle input[type="radio"]:checked + .label-text:before {
        content: "\F205";
        color: #16a085;
        animation: effect 250ms ease-in;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text:before {
            content: "\F204";
            color: #ccc;
    }

    /*Scroller */

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
    }



    @keyframes effect {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.3);
        }

        75% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1);
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .RoleSelect {
       /* background-repeat: no-repeat;
        background-image: url('/images/roles_background.jpeg');
        background-size: 100% 100%;*/
        color:#fff;
        padding:60px; 
    } 

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .RoleSelect {
        padding: 60px 0;
        color: #fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 800px;
            overflow: auto;
        }
}

.role-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(/static/media/sims_loginscreen.708027a0.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

@media all and (min-width: 480px) {

   
    .RoleSelect {
        //padding: 60px 0;
        color:#fff;  
       
    }

    .role-btn{
        background: #28A745 !important;
        color:#fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 400px;
            overflow: auto;
        }

        .RoleSelect p {
            margin-bottom: 32px;
            font-size: 30px;
        }

        .RoleSelect table {
            margin-top: 20px;
        }

    .RoleSelect label {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }

    .RoleSelect input[type="checkbox"], input[type="radio"] {
        position: absolute;
        right: 9000px;
    }

        /*Check box*/
        .RoleSelect input[type="checkbox"] + .label-text:before {
            content: "\F0C8";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="checkbox"]:checked + .label-text:before {
        content: "\F14A";
        color: #2980b9;
        animation: effect 250ms ease-in;
        font-weight: 900;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text:before {
        content: "\F0C8";
        color: #ccc;
    }

        /*Radio box*/

     .RoleSelect  input[type="radio"] + .label-text:before {
            content: "\F111";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="radio"]:checked + .label-text:before {
        content: "\F192";
        color: #000;
        animation: effect 250ms ease-in;
    }

        .RoleSelect input[type="radio"]:disabled + .label-text {
            color: #aaa;
        }

            input[type="radio"]:disabled + .label-text:before {
                content: "\F111";
                color: #ccc;
            }

    /*Radio Toggle*/

    .RoleSelect .toggle input[type="radio"] + .label-text:before {
        content: "\F204";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: 900;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .RoleSelect .toggle input[type="radio"]:checked + .label-text:before {
        content: "\F205";
        color: #16a085;
        animation: effect 250ms ease-in;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text:before {
            content: "\F204";
            color: #ccc;
    }

    /*Scroller */

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
    }



    @keyframes effect {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.3);
        }

        75% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1);
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .RoleSelect {
       /* background-repeat: no-repeat;
        background-image: url('/images/roles_background.jpeg');
        background-size: 100% 100%;*/
        color:#fff;
        padding:60px; 
    } 

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .RoleSelect {
        padding: 60px 0;
        color: #fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 800px;
            overflow: auto;
        }
}

