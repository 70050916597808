.table thead th {
}

table.responsive-table {
    display: table;
    /* required for table-layout to be used (not normally necessary; included for completeness) */
    table-layout: fixed;
    /* this keeps your columns with fixed with exactly the right width */
    width: 100% !important;
    /* table must have width set for fixed layout to work as expected */
    height: 100%;
}

table.responsive-table thead {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        width: 100%;
        height: 50px;
        line-height: 3em;
        background: #eee;
        table-layout: fixed;
        display: table;
    }

    table.responsive-table th {
        background: #eee;
    }

    table.responsive-table td {
        line-height: 2em;
    }

    table.responsive-table tr > td,
    table.responsive-table th {
        text-align: left;
    }

.table td, .table th {
    /*white-space: nowrap;*/
    overflow: hidden !important;
    text-overflow: ellipsis;
    min-width: 0 !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: none !important;
}

.selectCell {
    white-space: normal !important;
    text-overflow: unset !important;
}

.selectCell span{
    padding: 0 !important;
}

.table td {
    padding: 6px 24px 6px 16px;
    border-top: none !important;
    display: table-cell;
    font-size: 0.700rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /*font-weight: 400;*/
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.table th {
    /*color: rgba(0, 0, 0, 0.54);*/
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.3125rem;
    word-wrap:break-word;
}

.table {
    table-layout: fixed;
}

    .table-scrollbar {
    position: relative;
    overflow: auto;
}

@media screen and (max-width: 40em) {
 
  
}
